<template>
  <div class="settings">
    <app-redirect/>
    <div style="display:none;background:#f4f5f7; min-height: 100%;">
      <div :class="'mobileNavBar ' + deviceType">

        <div v-if="createSection" @click="hideCreateSection()" class="goBack" style="">
          <span  v-if="deviceType == 'ios'">
            <span style="height: 22px;position: absolute; margin-top: -1px; line-height: 22px; color: #007AFF; font-size: 17px; margin-left: 30px; font-weight: 400;">Geri</span>
            <img style="height: 22px; position: absolute;left: 12px; opacity: 1; line-height: 22px;"  :src="$root.CDN_SITE + 'icon/ios_back_laci.svg'">
          </span>
          <span  v-else>
             <img style="height: 18px; padding-left: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_arrow_back.svg'">
          </span>
        </div>
        <div v-else @click="routeBack('/', false)" class="goBack" style="">
          <span  v-if="deviceType == 'ios'">
            <span style="height: 22px;position: absolute; margin-top: -1px; line-height: 22px; color: #007AFF; font-size: 17px; margin-left: 30px; font-weight: 400;">Geri</span>
            <img style="height: 22px; position: absolute;left: 12px; opacity: 1; line-height: 22px;"  :src="$root.CDN_SITE + 'icon/ios_back_laci.svg'">
          </span>
          <span  v-else>
             <img style="height: 18px; padding-left: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_arrow_back.svg'">
          </span>
        </div>

        <div class="title" style=" color: #2d3640;">
          <span v-if="!createSection">Tüm Talepler</span>
          <span v-else>Yeni Talep Oluştur</span>
        </div>
        <div class="options" >
          <span v-if="!createSection" @click="showCreateSection()">
            <img v-if="deviceType == 'ios'" style="position:absolute;height: 18px;right: 16px;  line-height: 22px; " :src="$root.CDN_SITE + 'icon/ic_add_laci.svg'">
            <img v-else style="height: 18px; padding-right: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_add.svg'">
          </span>
        </div>

      </div>

      <div style="padding-top: 60px"></div>

      <div style="background:#f4f5f7;min-height: 240px;">
        <div style= "margin: 0 auto;width:100%; min-height: 100vh;">
          <div style="margin: 20px 20px 0;">

            <div style="min-height:100%;">


              <div style="background-color: white;padding: 0 15px 15px; border: 1px solid rgb(234, 237, 242);">
                <div v-if="ticketLoader">
                  <loader></loader>
                </div>
                <div v-else-if="!createSection && tickets.length" class="ticketList" style="">
                  <table>
                    <thead style="font-size: 12px; color: #8b95a1;">

                    <td width="400px;">Başlık</td>
                    <td width="120px;">En son Güncelleme</td>
                    </thead>
                  </table>

                  <table >
                    <tr v-for="ticket in tickets" >

                      <td width="450px;"><router-link :to="`/tickets/${ticket.uuid}`">{{ ticket.title }}</router-link></td>
                      <td width="220px;" style="font-size: 12px;">{{ Number(ticket.created_at) | customDateFormat('dd MMM yyyy') }}</td>
                    </tr>
                  </table>
                </div>
                <div v-else-if="!createSection && !tickets.length" style="font-size: 14px; padding: 8px 16px; text-align: center; background: white">
                  Henüz bir destek talebin yok

                  <div style=" margin-top:32px; margin-bottom: 16px; ">
                    <button v-if="!createSection" @click="showCreateSection()" class="super-button-small">
                      Yeni Talep Oluştur
                    </button>
                  </div>
                </div>

                <div v-if="createSection" style="font-size: 14px; line-height: 19px;">



                  <div class="ticket-question-body">
                    <div class="ticket-question-categories" v-if="ticketCategories.length">
                      <p class="ticket-question-header">Size hangi konuda yardımcı olabiliriz?</p>
                      <select v-model="selectedTicketCategory" required="required" tabindex="3" class="super-drop-tek-cizgi-review">
                        <option :value=null>Lütfen Seçin</option>
                        <option v-for="ticketCategory in ticketCategories" :value="ticketCategory">{{ ticketCategory.title}}</option>
                      </select>

                      <!--ticket alani-->
                      <div v-if="selectedTicketCategory" style="display: flex; flex-direction: column; margin-top: 20px; font-size: 16px; line-height: 19px;">

                        <textarea v-model="description" style="width: 88%;height: 100px" placeholder="Mesajınız..."></textarea>

                        <div v-for="file in files" style="font-size: 13px; margin-top: 3px; margin-bottom: 20px;">
                          <img src="https://gcdn.bionluk.com/site/icon/icon_attach_masmavi.svg" style="height: 12px; margin-top: 6px; position: absolute; display: inline-block;">
                          <a :href="file.url"  style="color: rgb(86, 165, 255); padding-left: 15px;" target="_blank">{{ file.filename }}</a>
                          <img @click="deleteFile(file.externalID)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" style=" cursor: pointer; opacity: .9; padding-left:12px;height: 14px; margin-top: 3px; position: absolute; display: inline-block;">
                        </div>

                        <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted.ticket[timeStamp]' max="100"></progress>
                        <div style="width: 100%; margin-top: 10px;">
                          <label  for="ticket_upload" class="uploadfile_text">
                            <img style="width: 16px;" src="https://gcdn.bionluk.com/site/icon/ic_attachment.svg"/>
                            Dosya Ekle
                          </label>
                          <input id="ticket_upload" style="display:none;" type="file" @change="onFileChange">

                          <button :disabled="disableToSave" @click="createTicket()" class="send-ticket-button" style="width: 100px; float: right">
                            Gönder
                          </button>
                          <div style="clear: both"></div>
                        </div>
                      </div>
                      <!--ticket alani-->


                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

  import appRedirect from "../../../components/appRedirect";
  import dropdown from 'vue-my-dropdown';

	export default {
	  name: "src-pages-body-tickets-ticketList-v1_mobile",
    components: {
      dropdown, appRedirect
    },
		data() {

			return {
        visible: false,
        position: [ "right", "top", "right", "top" ],
        animation: 'ani-slide-y',
				pageLoaded: null,
        tickets: [],
        createSection: false,
        ticketLoader: false,

        questions: [
          {
            label: "Freelancer'm",
            id: 'freelancer'
          },
          {
            label: "Alıcıyım",
            id: 'buyer'
          }
        ],
        selectedQuestionId: null,

        selectedTicketCategory: null,
        ticketCategories2: [],
        selectedTicketCategory2: null,
        disableRadioButton: false,
        ticketDescription: '',
        needMoreButtonClicked: false,

        // ticket
        title: null,
        description: null,
        files: [],
        uploadResponseStatus: null,
        timeStamp: null,


        ticketCategories: [],
        faqs:[],
        persona:'',
        selectedTicketCategoryId:null,
        currentStep: 1,
        buttonLoading:false,

        orders:[],
        orderOffset: 0,
        orderType: 'buying',
        orderBodyLoaded: false,
        orderSeemoreLoader: false,
        orderTotalCount: 0,
        orderSeemorediv: false,
        activeTabTimeStamp:null

      }
		},

    methods: {
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        this.uploadResponseStatus = "started";
        this.timeStamp = +new Date();
        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.TICKET, this.timeStamp)
        .then(result => {

        	let file = {
        		url: this.Constants.CDN_UPLOADS_G + result.uploadName,
            externalID: result.externalID,
            filename: result.uploadName
          };

          this.files.push(file);
          this.uploadResponseStatus = null;
          e.target.value = '';
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.uploadResponseStatus = null;
          e.target.value = '';
        });
      },

      createTicket() {
      	let uploadIDs = this.files.map(function (file) {
          return file.externalID;
        });


        let selectedTicketCategoryTitle = this.ticketCategories.find(({ id }) => id === this.selectedTicketCategoryId).title
        this.buttonLoading = true;
        this.api.general.createTicketV2(selectedTicketCategoryTitle, this.description, uploadIDs, this.selectedTicketCategoryId, this.persona, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.buttonLoading = false;
              this.$toasted.global.infoToast({description: result.message});
              let uuid = result.data.ticket.uuid;
              this.$router.push(`/tickets/${uuid}`);

            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          });

      },

      deleteTicket(uuid) {
        if (confirm(this.Constants.TEXTS.TICKET_DELETE_WARNING_DESC)) {
          this.api.general.deleteTicket(uuid, this.$Progress)
          .then(({data}) => {
          	let result = data;

          	if (result.success) {
          		this.tickets = this.tickets.filter(function (ticket) {
                return ticket.uuid !== uuid;
              });

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
        }
      },

      showCreateSection() {
        this.createSection = true;
        this.pageLoaded = false;
        this.api.content
          .getTicketCategoriesv2(this.selectedTicketCategoryId, null)
          .then(({ data }) => {
            let result = data;
            if (result.success) {
              this.pageLoaded = true;
              this.ticketCategories = result.data.ticket_categories
              this.faqs = result.data.faqs;
              this.persona = result.data.persona;
              window.scrollTo(0,0);
            } else {
              this.pageLoaded = true;
              this.$toasted.global.errorToast({
                description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
              });
              this.$router.push(result.redirect_url);
            }
            this.currentStep++;
            this.pageLoaded = true;
          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
          });
      },

      hideCreateSection() {
        this.createSection = false;
        this.title = null;
        this.files = [];
        this.timeStamp = null;
      },

      deleteFile(uploadID) {
        this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
        .then(({data}) => {
        	let result = data;

        	if (result.success) {
            this.files = this.files.filter(function (file) {
              return file.externalID !== uploadID
            });

            this.$toasted.global.infoToast({description: result.message});
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },



      resetState(isCase) {
        // 0 ise => 0 , 1 ve 2 yi calistiracak yani butun state'yi sifirlayacak.
        // Birinde olup digerinde olmayacak bir sey var ise break konularak tekrar yazilmali
        switch (isCase) {
          case 0:
            this.ticketCategories = [];
            this.selectedTicketCategory = null;
          case 1:
            this.ticketCategories2 = [];
            this.selectedTicketCategory2 = null;
          case  2 :
            this.ticketDescription = '';
            this.title = '';
            this.needMoreButtonClicked = false;
            this.files = [];
            break;
        }
      },

      clickNeedMoreButton() {
        this.title = {
          parent: this.selectedTicketCategory.name,
          child: this.selectedTicketCategory2.name
        };
        this.needMoreButtonClicked = true;
      },
    },

    watch: {
      selectedTicketCategory: function (newVal, oldVal) {
        if(newVal){
          this.selectedTicketCategoryId = newVal.id;
        }
      }
    },

    computed: {
			disableToSave() {
				return !this.description || this.buttonLoading;
      }
    },

    created() {
      this.ticketLoader = true;
      this.api.general.retrieveTickets()
      .then(({data}) => {
      	let result = data;

      	if (result.success) {
          result.data.tickets.forEach(function (ticket) {
            ticket.visible = false;
          });
          this.tickets = result.data.tickets;
          this.ticketLoader = false;
        } else {
          this.$toasted.global.errorToast({description: result.message});
        }
      })
      .catch(err => {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
      });
    }
	}

</script>

<style scoped>

  .menu-list {
    line-height: 1.25;

  }

  ul {
    list-style: none;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  li:hover {
    display: list-item;
    text-align: -webkit-match-parent;
    color: #2d3640;
  }

  .menu-list li {
    cursor: pointer;
    border-radius: 2px;
    color: #4a4a4a;
    display: block;
    padding: .5em .75em;
    z-index: 99999;
  }
  .menu-list li:hover {
    border-radius: 2px;
    color: #2d3640;
    display: block;
    padding: .5em .75em;
    text-decoration: none;
  }


  .uploadfile_text{
    float: left;  cursor: pointer; font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  .uploadfile_text:hover{
    text-decoration: underline;
  }

  /*ticket question*/
  .ticket-question-body {
    margin-top: 50px;
    border: 2px solid rgb(234, 237, 242);
    padding: 15px;

  }

  .ticket-question-radio-div {
    margin-bottom: 10px;
    display: flex;
  }

  .categories-label {
    font-size: 16px;
    color: #a1a9a9;
    margin-left: 15px;
    display: unset;
    cursor: pointer;
  }

  label {
    margin-bottom: 0;
  }

  input[type=radio] {
    display: none;
  }

  input[type=radio] + .radio-label {
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    cursor: pointer;
    margin-left: 15px;
    background: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-unselected.svg) no-repeat;
  }

  input[type=radio]:checked + .radio-label {
    display: inline-block;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-left: 15px;
    background-image: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-selected.svg);
  }

  .super-drop-tek-cizgi-review {
    margin-bottom: 15px;
    background-color: #ffffff;
    font-size: 14px;
    color: #4b4f52;
    text-align: center;
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: calc(100% - 20px);
    -moz-appearance: none;
    min-width: 250px;
    border-radius: 2px;
    background-color: #fefdfd;
    padding: 1px 5px 8px 10px;
    font-family: 'sofia-pro';
  }

  .ticket-question-categories {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .ticket-question-header {
    margin-bottom: 15px;
  }

  .need-more-help-button {
    cursor: pointer;
    margin-top: 30px;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
  }

  .send-ticket-button {
    cursor: pointer;
    width: 100px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fd4056;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
  }

  .progress-bar {
    width: 200px;
    margin: 20px 0 0;
  }



</style>
